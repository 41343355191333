import Link from '@mui/material/Link';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';

const eyeAbleUrlMapping = {
    EAblog: {
        de: 'https://eye-able.com/de/blog',
        en: 'https://eye-able.com/blog/'
    },
    EAaudit: {
        de: 'https://eye-able.com/de/barrierefreiheits-pruefsoftware-audit',
        en: 'https://eye-able.com/audit-wcag-testing-software/'
    },
    EAassist: {
        de: 'https://eye-able.com/de/assistenzsoftware/',
        en: 'https://eye-able.com/assist-assistance-software'
    },
    EAmission: {
        de: 'https://eye-able.com/de/unsere-vision/',
        en: 'https://eye-able.com/vision-eye-able/'
    },
    EAwordpressInstallation: {
        de: 'https://services.eye-able.com/wordpress-plugin-installation/',
        en: 'https://services.eye-able.com/en/wordpress-plugin-installation/'
    }
};

const LinkBehavior = forwardRef(function LinkBehaviorRef(props, ref) {
    return <ReactRouterLink ref={ref} {...props} />;
});

const RouterLink = forwardRef(function RouterLinkRef(props, ref) {
    const { i18n } = useTranslation();
    const lng = i18n.resolvedLanguage;

    const { external, sx, ...rest } = props;

    let to = props.to;

    let isExternal = external || !!props?.to?.startsWith('http');

    if (eyeAbleUrlMapping[to]) {
        isExternal = true;

        if (eyeAbleUrlMapping[to][lng]) to = eyeAbleUrlMapping[to][lng];
        else if (eyeAbleUrlMapping[to]['en']) {
            // use the english version and add the language prefix
            to = eyeAbleUrlMapping[to]['en'];

            if (to.includes('eye-able.com/en/'))
                to = to.replace('eye-able.com/en/', 'eye-able.com/' + lng + '/');
            else to = to.replace('eye-able.com/', 'eye-able.com/' + lng + '/');
        }
    } else if (isExternal) {
        if (to?.startsWith('https://eye-able.com/')) {
            let url = new URL(props.to);
            let path = url.pathname; // eg '/wcag-checker/'
            let origin = url.origin; // 'https://services.eye-able.com'

            // fix for audit rules where each link starts with en
            if (path.includes('/en/eye-able-audit-regel-')) {
                origin = 'https://services.eye-able.com';
                path = path.replace('/en/', '/' + lng + '/');
            } else if (path.startsWith('/en/')) {
                if (lng === 'en')
                    path = path.replace('/en/', '/'); // english site must not have language prefix
                else path = path.replace('/en/', '/' + lng + '/');
            } else if (path.startsWith('/de/')) {
                path = path.replace('/de/', '/' + lng + '/');
            } else if (lng !== 'en') {
                // english site must not have language prefix
                path = '/' + i18n.resolvedLanguage + path;
            }

            to = origin + path;
        } else if (to?.startsWith('https://services.eye-able.com/')) {
            let url = new URL(props.to);
            let path = url.pathname; // eg '/wcag-checker/'
            let origin = url.origin; // 'https://services.eye-able.com'

            if (path.startsWith('/en/')) {
                path = path.replace('/en/', '/' + lng + '/');
            } else if (path.startsWith('/de/')) {
                path = path.replace('/de/', '/' + lng + '/');
            } else {
                path = '/' + i18n.resolvedLanguage + path;
            }

            to = origin + path;
        }
    }

    const linkProps = {
        fontSize: 'inherit',
        underline: 'hover',
        role: undefined,
        target: isExternal ? '_blank' : '_self',
        rel: 'noopener noreferrer',
        color: isExternal ? '#003fab' : 'inherit',
        sx: theme => ({
            'textDecorationColor': 'inherit !important',
            '&:focus:not(.MuiButtonBase-root)': {
                outline: `3px solid ${theme.palette.secondary.dark}`,
                outlineOffset: '2px'
            },
            '&:hover:not(.MuiButtonBase-root)': isExternal
                ? {
                      filter: 'brightness(1.25)'
                  }
                : {},
            ...sx
        }),
        ...rest,
        to
    };

    return (
        <Link {...linkProps} component={LinkBehavior} ref={ref}>
            {linkProps.children}
        </Link>
    );
});

export default RouterLink;
